import Turbolinks from 'turbolinks'
import Rails from '@rails/ujs'
import SweetScroll from 'sweet-scroll'
import 'controllers'
import 'alpinejs'

Turbolinks.start()
Rails.start()

import '../css/application.scss'
import 'typeface-didact-gothic'
import 'typeface-crimson-text'
import 'typeface-dancing-script'
require.context('../images', true)

new SweetScroll()
