import { Controller } from 'stimulus'

import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 5,
        },
      },
    })
  }
}
